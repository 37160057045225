import Vue from "vue";
import App from "./App.vue";
// import router from "./router/index";
import tween from "./plugins/tween";
import "./assets/font.css";

// let flag = "ontouchstart" in window;
// // console.log("flag:", flag);
// if (flag) {
//   // 引入移动端样式
//   var head = document.getElementsByTagName("head")[0];
//   var link = document.createElement("link");
//   link.href = "/mobil.css";
//   link.rel = "stylesheet";
//   link.type = "text/css";
//   head.appendChild(link);
// }

Vue.use(tween);

Vue.config.productionTip = false;
Vue.directive("focus", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el) {
    // 聚焦元素
    el.focus();
  },
});

new Vue({
  // router,
  render: (h) => h(App),
}).$mount("#app");
