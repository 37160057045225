<template>
  <div class="bgWrap">
    <!-- 游戏绘图区域 -->
    <div class="gameContent" id="gameContent" ref="gameContent" @click="notifyDeviceData(0, 20, 0)"></div>

    <!-- 游戏首页 -->
    <div class="mask" v-if="currentSetp == 0">
      <!-- 首页-背景图 -->
      <img class="indexBg1" :src="indexBg0" :style="{ width: clientWidth + 'px', height: clientHeight + 'px' }" />
      <div class="box-center">
        <h4 class="title"></h4>
        <div class="progressBar">
          <div ref="tag" class="tag" :style="{ width: percent }"></div>
        </div>
        <a tabindex="1" ref="palyBtn" class="borderImage palyBtn btn1 transition-scale" @click="playGame">
          开始游戏
        </a>
      </div>

      <img src="../assets/images/logo.png" alt="" class="logo" />

      <p class="copyright">
        ©2021 KIKI & NUNA. Suzhou Aola Animation Technology Co. Ltd. All rights reserved. Licensed by Medialink Cultural
        & Creative (Beijing) Limited
        <!-- ©2021 KIKI &amp; NUNA. Suzhou Aola Animation Technology Co. Ltd. All rights reserved. Licensed by Medialink Cultural &amp; Creative (Beijing) Limited -->
      </p>
    </div>
    <!-- 选择难度 角色 地图 -->
    <div class="mask" v-show="currentSetp == 1 || currentSetp == 2">
      <img
        :src="indexBg1"
        :style="{
          position: 'absolute',
          top: 0,
          left: 0,
          width: clientWidth + 'px',
          height: clientHeight + 'px',
          objectFit: 'cover',
        }"
      />
      <div class="selectModelWrap">
        <div class="container" v-show="currentSetp == 1">
          <!-- 请选择你喜欢的角色-img -->
          <div class="top">
            <div
              class="title"
              :style="{
                background: 'url(' + title01 + ') no-repeat center/contain',
                height: 0.12 * clientHeight + 'px',
              }"
            ></div>
          </div>

          <!-- 角色选择-02-start -->
          <div class="select-wrap select-wrap-02">
            <a :class="['item', currentRole == index ? 'active' : '']" v-for="(item, index) in roleList" :key="index">
              <img src="../assets/images/role/bg.png" class="bgimg" />
              <a ref="item" :tabindex="index + 10" @focus="selectRole(index, false)" @click="selectRole(index, true)">
                <img class="transition-scale" :src="item.cover02" style="{object-fil: contain}" />
              </a>
              <div :class="['role-name', currentRole == index ? 'active' : '']">{{ item.name }}</div>
            </a>
          </div>
          <!-- 角色选择-02-end -->

          <div class="footer"></div>
        </div>

        <div class="hd-box style-02" v-show="currentSetp == 2 && queryMap === ''">
          <!-- 选择地图-02 start -->
          <div class="top">
            <div
              class="title"
              :style="{
                background: 'url(' + title02 + ') no-repeat center/contain',
                height: 0.12 * clientHeight + 'px',
              }"
            ></div>
          </div>
          <div class="model">
            <div class="item" v-for="(item, index) in mapList" :key="index">
              <button
                :class="['btn', valueMap === index ? 'active' : '']"
                :style="{ background: `url(${item.cover}) no-repeat center/cover` }"
                @focus="setcurrentSetp(index)"
                @click="setcurrentSetp(index)"
              ></button>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
          <!-- 选择地图-02 end -->
        </div>
        <div class="hb-box">
          <button class="borderImage btn btn3 transition-scale" v-if="currentSetp != 1" @click="loadingHandle">
            确定
          </button>
        </div>
      </div>
    </div>

    <!-- currentSetp == 4 游戏结算-->
    <!-- <div class="mask bg-black" v-if="true"> -->
    <div
      class="mask bg-black"
      :style="{ width: clientWidth + 'px', height: clientHeight + 'px' }"
      v-show="currentSetp == 4"
    >
      <div style="margin-top: 30px"></div>
      <div class="img-wrap">
        <!-- 成功 -->
        <img v-if="score >= maxScore" src="../assets/images/record_bg.png" />
        <!-- 失败 -->
        <img v-else src="../assets/images/record_bg1.png" />
        <div class="text">
          <div>恭喜你完成本轮游戏</div>
          <div>获得{{ this.score }}金币</div>
        </div>
        <div class="btn-wrap">
          <button class="recordBtn transition-scale" @click="currentSetp++">查看排名</button>
          <button
            class="recordBtn transition-scale"
            v-if="score >= maxScore && currentMaplevels != 2"
            @click="next(true)"
          >
            下一关
          </button>
          <button class="recordBtn transition-scale" v-if="score < maxScore" @click="next(false)">重新开始</button>
        </div>
      </div>
    </div>

    <!-- 排行榜 -->
    <div class="mask" v-if="currentSetp == 5">
      <img
        :src="indexBg1"
        :style="{
          position: 'absolute',
          top: 0,
          left: 0,
          width: clientWidth + 'px',
          height: clientHeight + 'px',
          objectFit: 'cover',
        }"
      />
      <!-- <div class="title">排行榜</div> -->
      <img src="../assets/images/phb.png" class="title" alt="" />
      <div class="rankingList-wrap">
        <div class="hd-box">
          <div class="left">
            <div class="list list-hd">
              <div class="item th">
                <div class="ranking">排名</div>
                <div class="avatar">头像</div>
                <div class="name flex">昵称</div>
                <div class="score">总分</div>
              </div>
            </div>
            <div class="list list-item scorll-wrap">
              <div class="item" v-for="(item, index) in showGameRecord" :key="index">
                <!-- <img class="avatar" src="../assets/images/avatar.png" /> -->
                <div class="ranking">{{ item.NO }}</div>
                <div class="avatar">
                  <img class="tag" v-if="item.NO == 1" src="../assets/images/crown1.png" />
                  <img class="tag" v-if="item.NO == 2" src="../assets/images/crown2.png" />
                  <img class="tag" v-if="item.NO == 3" src="../assets/images/crown3.png" />
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="name flex" style="color:#FA6400">{{ item.user_name }}</div>
                <div class="score" style="color:#FA6400">{{ item.game_recor }}</div>
              </div>
            </div>

            <div class="arrows">
              <a tabindex="2" class="btn prev" prev="" @click="setPage('prev')"></a>
              <a tabindex="3" class="btn next" next="" @click="setPage('next')"></a>
            </div>
          </div>
          <div class="right">
            <!-- <img class="myAvatar" src="../assets/images/avatar.png" /> -->
            <img class="myAvatar" :src="gameRecord.avatar" />
            <div class="myName">{{ gameRecord.userName }}</div>
            <div class="myLabel">当前得分</div>
            <div class="myScore">{{ gameRecord.gameRecor }}</div>
            <div class="myLabel">最佳排名</div>
            <div class="myRanking">{{ gameRecord.NO }}</div>
          </div>
        </div>
        <div class="hb-box">
          <button
            class="borderImage toPalyBtn btn5 transition-scale"
            @click="toAGame"
            :style="{ height: 0.12 * clientHeight + 'px' }"
          >
            重新开始
          </button>
          <button
            class="borderImage toPalyBtn btn5 transition-scale"
            v-if="score >= maxScore && currentMaplevels != 2"
            :style="{ height: 0.12 * clientHeight + 'px' }"
            @click="next(true)"
          >
            下一关
          </button>
        </div>
      </div>
    </div>

    <!-- header-->
    <div class="header-fixed">
      <div class="left">
        <score :avatar="role.avatar" :score="score" :max-score="maxScore"></score>
      </div>
      <div class="right">
        <div class="container">
          <!-- <div class="img-wrap"></div>
          <div class="countDown">{{ countDown }}</div> -->
          <div class="progress" :style="{ width: countWidth }">
            <!-- :style="{ marginLeft: countDownMR + 'px' }" -->
            <span class="conutDown">{{ countDown }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 游戏进行时音乐 -->
    <audio ref="bgMusic" loop>
      <source src="../assets/audio/滑稽可爱.mp3" />
    </audio>
    <!-- 游戏前奏音乐 -->
    <audio ref="preludeMusic" loop>
      <source src="../assets/audio/开心糖果.mp3" />
    </audio>
    <!-- 起跳音效 -->
    <audio ref="upMusic">
      <source src="../assets/audio/up.mp3" />
    </audio>
    <!-- 得分音乐 -->
    <audio ref="scoreMusic">
      <source src="../assets/audio/scoreMusic.mp3" />
    </audio>
    <div class="loading" v-if="loading">
      <img src="../assets/images/00.gif" class="img" />
    </div>

    <!-- <div style="position:fixed;top:50px;left:50px;fontSize:40px;zIndex:9999">
      userId: {{ userInfo.userId }} <br />
      userName: {{ userInfo.userName }}<br />
      enviroment: {{ userInfo.enviroment }}
    </div> -->
  </div>
</template>

<script>
import score from "../components/score.vue";
import "../plugins/phaser-arcade-physics.min.js";
import checkScreen from "../plugins/checkscreen.js";
import axios from "axios";

// 预加载图片
import imgPreloaderList from "../plugins/imgPreloaderList.js";

let lastTime = 0; //
const speed = 0.0012; // 每次绘制时 地图与景点的偏移量
var JUMP_FORCE = -1300;
export default {
  components: {
    score,
  },
  data() {
    return {
      gameIsOver: false,
      userInfo: {
        userId: "0",
        userName: "来宾账号",
        enviroment: "",
      },
      deviceData: {
        speed: 0,
        acceleration: 0,
        height: 0,
      },
      deviceStatus: "", // 0断开 1连接
      clientWidth: 1334,
      clientHeight: 750,
      score: 0, // 得分
      maxScore: 10,
      isRoleUp: false, // 角色跳跃状态的标识
      upSum: 0, // 跳跃高度
      title01: require("../assets/images/title01.png"),
      title02: require("../assets/images/title02.png"),
      indexBg0: require("../assets/images/bg0.jpeg"),
      indexBg1: require("../assets/images/bg1.png"),
      indexBg2: require("../assets/images/cover_map.jpeg"),
      indexBg3: require("../assets/images/bg3.png"),
      indexRole1: require("../assets/images/role1.png"),
      indexProp1: require("../assets/images/prop1.png"),
      indexProp2: require("../assets/images/prop2.png"),
      indexRole: require("../assets/images/role/qiqi.png"),
      currentSetp: 0, // ["加载","选择角色","选择地图","游戏中","游戏结算","排行榜"]
      currentRole: 0, // 角色的索引
      currentMap: 0, // 地图的索引
      valueMap: "",
      currentMaplevels: 0, // 地图关卡的索引
      mapList: [
        {
          cover: require("../assets/images/cover_bj.jpeg"),
          url: [
            [
              require("../assets/images/map/北京/1/1.png"),
              require("../assets/images/map/北京/1/2.png"),
              require("../assets/images/map/北京/1/3.png"),
              require("../assets/images/map/北京/1/4.png"),
              require("../assets/images/map/北京/1/5.png"),
              require("../assets/images/map/北京/1/6.png"),
            ],
            [
              require("../assets/images/map/北京/2/1.png"),
              require("../assets/images/map/北京/2/2.png"),
              require("../assets/images/map/北京/2/3.png"),
              require("../assets/images/map/北京/2/4.png"),
              require("../assets/images/map/北京/2/5.png"),
              require("../assets/images/map/北京/2/6.png"),
            ],
            [
              require("../assets/images/map/北京/3/1.png"),
              require("../assets/images/map/北京/3/2.png"),
              require("../assets/images/map/北京/3/3.png"),
              require("../assets/images/map/北京/3/4.png"),
              require("../assets/images/map/北京/3/5.png"),
              require("../assets/images/map/北京/3/6.png"),
            ],
          ],
          width: [17182, 17182, 17182],
          height: [750, 750, 750],
          baselineCoefficient: 0.02, // 背景中地面到图片底部距离在整张图片中的占比
        },
        {
          cover: require("../assets/images/cover_ts.jpeg"),
          url: [
            [
              require("../assets/images/map/泰山/1/1.png"),
              require("../assets/images/map/泰山/1/2.png"),
              require("../assets/images/map/泰山/1/3.png"),
              require("../assets/images/map/泰山/1/4.png"),
              require("../assets/images/map/泰山/1/5.png"),
              require("../assets/images/map/泰山/1/6.png"),
            ],
            [
              require("../assets/images/map/泰山/2/1.png"),
              require("../assets/images/map/泰山/2/2.png"),
              require("../assets/images/map/泰山/2/3.png"),
              require("../assets/images/map/泰山/2/4.png"),
              require("../assets/images/map/泰山/2/5.png"),
              require("../assets/images/map/泰山/2/6.png"),
            ],
            [
              require("../assets/images/map/泰山/3/1.png"),
              require("../assets/images/map/泰山/3/2.png"),
              require("../assets/images/map/泰山/3/3.png"),
              require("../assets/images/map/泰山/3/4.png"),
              require("../assets/images/map/泰山/3/5.png"),
              require("../assets/images/map/泰山/3/6.png"),
            ],
          ],
          width: [17182, 17182, 17182],
          height: [750, 750, 750],
          baselineCoefficient: 0.02, // 背景中地面到图片底部距离在整张图片中的占比
        },
        {
          cover: require("../assets/images/cover_xa.jpeg"),
          url: [
            [
              require("../assets/images/map/西安/1/1.png"),
              require("../assets/images/map/西安/1/2.png"),
              require("../assets/images/map/西安/1/3.png"),
              require("../assets/images/map/西安/1/4.png"),
              require("../assets/images/map/西安/1/5.png"),
              require("../assets/images/map/西安/1/6.png"),
            ],
            [
              require("../assets/images/map/西安/2/1.png"),
              require("../assets/images/map/西安/2/2.png"),
              require("../assets/images/map/西安/2/3.png"),
              require("../assets/images/map/西安/2/4.png"),
              require("../assets/images/map/西安/2/5.png"),
              require("../assets/images/map/西安/2/6.png"),
            ],
            [
              require("../assets/images/map/西安/3/1.png"),
              require("../assets/images/map/西安/3/2.png"),
              require("../assets/images/map/西安/3/3.png"),
              require("../assets/images/map/西安/3/4.png"),
              require("../assets/images/map/西安/3/5.png"),
              require("../assets/images/map/西安/3/6.png"),
            ],
          ],
          width: [17182, 17182, 17182],
          height: [750, 750, 750],
          baselineCoefficient: 0.02, // 背景中地面到图片底部距离在整张图片中的占比
        },
      ],
      roleList: [
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/1.png"),
          cover: require("../assets/images/role/1.png"),
          cover02: require("../assets/images/role/奇奇.png"),
          url: require("../assets/images/role/qiqi.png"),
          gif: "",
          UP: require("../assets/images/role/qiqi.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "奇奇",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/2.png"),
          cover: require("../assets/images/role/2.png"),
          cover02: require("../assets/images/role/努娜.png"),
          url: require("../assets/images/role/nuna.png"),
          gif: "",
          UP: require("../assets/images/role/nuna.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "努娜",
        },

        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/4.png"),
          cover: require("../assets/images/role/4.png"),
          cover02: require("../assets/images/role/珀珀.png"),
          url: require("../assets/images/role/popo.png"),
          gif: "",
          UP: require("../assets/images/role/popo.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "珀珀",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/5.png"),
          cover: require("../assets/images/role/5.png"),
          cover02: require("../assets/images/role/沐沐.png"),
          url: require("../assets/images/role/mumu.png"),
          gif: "",
          UP: require("../assets/images/role/mumu.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "沐沐",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/6.png"),
          cover: require("../assets/images/role/6.png"),
          cover02: require("../assets/images/role/妮妮.png"),
          url: require("../assets/images/role/nini.png"),
          gif: "",
          UP: require("../assets/images/role/nini.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "妮妮",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/3.png"),
          cover: require("../assets/images/role/3.png"),
          cover02: require("../assets/images/role/西西.png"),
          url: require("../assets/images/role/xixi.png"),
          gif: "",
          UP: require("../assets/images/role/xixi.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "西西",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/7.png"),
          cover: require("../assets/images/role/7.png"),
          cover02: require("../assets/images/role/A博士.png"),
          url: require("../assets/images/role/Aboshi.png"),
          gif: "",
          UP: require("../assets/images/role/Aboshi.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "A博士",
        },
        {
          width: 750 * 0.2,
          height: 750 * 0.2,
          avatar: require("../assets/images/role/8.png"),
          cover: require("../assets/images/role/8.png"),
          cover02: require("../assets/images/role/小优.png"),
          url: require("../assets/images/role/xiaoyou.png"),
          gif: "",
          UP: require("../assets/images/role/xiaoyou.png"),
          keyframe: 15, // 关键帧
          duration: 15 * 0.05, // 动画持续时间 单位 s
          baselineCoefficient: 0,
          runAnimationName: "run",
          upAnimationName: "up",
          row: 3,
          column: 5,
          name: "小优",
        },
      ],
      page: 0,
      gameRecord: {},
      gameRecordList: [],
      // ------------------------
      index: 0, //
      landmarkText: "",
      landmarkHistory: [
        {
          ref: [
            ["landmark-bj-1_1", "landmark-bj-1_2", "landmark-bj-1_3"],
            ["landmark-bj-2_1", "landmark-bj-2_2", "landmark-bj-2_3"],
            ["landmark-bj-3_1", "landmark-bj-3_2", "landmark-bj-3_3"],
          ],
          title: [
            ["万里长城", "北京故宫", "华 表"],
            ["圆 明 园", "天 安 门", "水 立 方"],
            ["人民大会堂", "鸟 巢", "天 坛"],
          ],
          img: [
            [
              require("../assets/images/landmark/长城.png"),
              require("../assets/images/landmark/故宫.png"),
              require("../assets/images/landmark/华表.png"),
            ],
            [
              require("../assets/images/landmark/圆明园.png"),
              require("../assets/images/landmark/天安门.png"),
              require("../assets/images/landmark/水立方.png"),
            ],
            [
              require("../assets/images/landmark/人民大会堂.png"),
              require("../assets/images/landmark/鸟巢.png"),
              require("../assets/images/landmark/天坛.png"),
            ],
          ],
          audioUrl: [
            [
              require("../assets/audio/北京/长城.mp3"),
              require("../assets/audio/北京/故宫.mp3"),
              require("../assets/audio/北京/华表.mp3"),
            ],
            [
              require("../assets/audio/北京/圆明园.mp3"),
              require("../assets/audio/北京/天安门.mp3"),
              require("../assets/audio/北京/水立方.mp3"),
            ],
            [
              require("../assets/audio/北京/人民大会堂.mp3"),
              require("../assets/audio/北京/鸟巢.mp3"),
              require("../assets/audio/北京/天坛.mp3"),
            ],
          ],
          point: [
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
          ],
          desc: [
            [
              "长城，又称万里长城，总长超过2.1万千米，是世界中古七大奇迹之一，也是中国古代的军事防御工事，是一道高大、坚固而且连绵不断的以大量的城、障、亭、标相结合的防御体系。",
              "北京故宫是中国明清两代的皇家宫殿，旧称紫禁城，位于北京中轴线的中心。北京故宫以三大殿为中心，占地面积约72万平方米，建筑面积约15万平方米，有大小宫殿七十多座，房屋九千余间。",
              "华表，相传是部落时代的一种图腾标志，古称桓表，以一种望柱的形式出现，富有深厚的中国传统文化内涵，散发出中国传统文化的精神、气质、神韵。",
            ],
            [
              "圆明园，始建于1707年，由圆明园及其附园长春园和绮春园组成，也称为圆明三园，有“万园之园”之称 。清帝每到盛夏就来此避暑、听政，故又称“夏宫”。1860年第二次鸦片战争期间，圆明园遭英法联军洗劫并烧毁 ，故址现为圆明园遗址公园。",
              "天安门，始建于明朝永乐十五年（公元1417年），总高34.7米，楼长66米、宽37米。位于北京皇城中轴线上，过去只有皇帝才可以由此出入。正中门洞上方悬挂着毛泽东画像，两边分别是“中华人民共和国万岁”和“世界人民大团结万岁”的大幅标语。",
              "国家游泳中心又称”水立方”,水立方位于北京奥林匹克公园内，是北京为2008年夏季奥运会修建的主游泳馆，也是2008年北京奥运会标志性建筑物之一。",
            ],
            [
              "人民大会堂，是党、国家领导人和人民群众举行政治、外交、文化活动的重要场所。每年一届的全国人民代表大会、中国人民政治协商会议以及五年一届的中国共产党全国代表大会也在此召开。",
              "鸟巢国家体育场，为2008年北京奥运会的主体育场，占地20.4公顷，建筑面积25.8万平方米，可容纳观众9.1万人，是地标性的体育建筑和奥运遗产。",
              "天坛公园在明、清两代是帝王祭祀皇天、祈五谷丰登之场所。天坛是圜丘、祈谷两坛的总称，有坛墙两重，形成内外坛，坛墙南方北圆，象征天圆地方。",
            ],
          ],
        },

        {
          ref: [
            ["landmark-ts-1_1", "landmark-ts-1_2", "landmark-ts-1_3"],
            ["landmark-ts-2_1", "landmark-ts-2_2", "landmark-ts-2_3"],
            ["landmark-ts-3_1", "landmark-ts-3_2", "landmark-ts-3_3"],
          ],
          title: [
            ["岱 庙", "红 门 宫", "中 天 门"],
            ["泰山十八盘", "南 天 门", "天 街"],
            ["五岳独尊", "日 观 峰", "玉 皇 顶"],
          ],
          img: [
            [
              require("../assets/images/landmark/岱庙.png"),
              require("../assets/images/landmark/红门.png"),
              require("../assets/images/landmark/中天门.png"),
            ],
            [
              require("../assets/images/landmark/十八盘.png"),
              require("../assets/images/landmark/南天门.png"),
              require("../assets/images/landmark/天街.png"),
            ],
            [
              require("../assets/images/landmark/五岳独尊.png"),
              require("../assets/images/landmark/日观峰.png"),
              require("../assets/images/landmark/玉皇顶.png"),
            ],
          ],
          audioUrl: [
            [
              require("../assets/audio/泰安/岱庙.mp3"),
              require("../assets/audio/泰安/红门.mp3"),
              require("../assets/audio/泰安/中天门.mp3"),
            ],
            [
              require("../assets/audio/泰安/泰山十八盘.mp3"),
              require("../assets/audio/泰安/南天门.mp3"),
              require("../assets/audio/泰安/泰山天街.mp3"),
            ],
            [
              require("../assets/audio/泰安/五岳独尊.mp3"),
              require("../assets/audio/泰安/日观峰.mp3"),
              require("../assets/audio/泰安/玉皇顶.mp3"),
            ],
          ],
          point: [
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
          ],
          desc: [
            [
              "岱庙，位于山东省泰安市泰山南麓，俗称“东岳庙”。始建于汉代，是历代帝王举行封禅大典和祭拜泰山神的地方。岱庙与北京故宫、山东曲阜三孔、承德避暑山庄的外八庙，并称中国四大古建筑群。",
              "红门宫，是泰山中溪的门户，呈半封闭凹形空间。再加宫前三重白色石坊及碑碣，构成一组高低错落、色彩鲜明、形若天梯的古建筑群。",
              "中天门，是泰山登山东、西两路的交汇点。此处为登顶半程，上下必经之地。中溪山北侧为东溪，俗称大直沟，古为登岱东路，后废弃。中天门建于清，为两柱单门式石坊。",
            ],
            [
              "泰山十八盘，是泰山登山盘路中最险要的一段，共有石阶1600余级，为泰山的主要标志之一。远远望去，恰似天门云梯，泰山之雄伟，尽在十八盘，泰山之壮美，尽在攀登中。",
              "南天门，又名三天门。南天门位于十八盘尽头，是登山盘道顶端，座落在飞龙岩和翔凤岭之间的山口上。由下仰视，犹如天上宫阙，是登泰山顶的门户。",
              "泰山天街，全长约一华里，包括南天门向北的一段路，约有100米，称为北天街，岱顶天街，商铺林立，亦市亦街，形成了特有的风俗。",
            ],
            [
              "五岳独尊，位于泰山极顶，泰山是历代帝王、文人墨客的往来胜地，留下了众多文物古迹。除庙宇、塑像之外，山上共有两千多处摩崖石刻，其中“五岳独尊”属众多古迹石刻中最具代表性的杰作，已成为泰山的标志，并被设计进人民币的背景图案。",
              "日观峰，山东泰山游览名胜。位于玉皇顶东南，古称介丘岩，因观日出而闻名。观日长廊全长30米，亭廊衔接，似仙阁矗立，鲜艳夺目。",
              "玉皇顶，是五岳之首泰山的主峰之巅，因峰顶有玉皇殿而得名，相传最初秦始皇曾到此祭天。自秦始皇开始到清代，先后有13代帝王亲登泰山封禅或祭祀，另外有24代帝王遣官祭祀72次。",
            ],
          ],
        },

        {
          ref: [
            ["landmark-xa-1_1", "landmark-xa-1_2", "landmark-xa-1_3"],
            ["landmark-xa-2_1", "landmark-xa-2_2", "landmark-xa-2_3"],
            ["landmark-xa-3_1", "landmark-xa-3_2", "landmark-xa-3_3"],
          ],
          title: [
            ["大 雁 塔", "大唐芙蓉园", "西安鼓楼"],
            ["壶口瀑布", "西安钟楼", "华 清 宫"],
            ["大唐不夜城", "灞 桥", "兵 马 俑"],
          ],
          img: [
            [
              require("../assets/images/landmark/大雁塔.png"),
              require("../assets/images/landmark/大唐芙蓉园.png"),
              require("../assets/images/landmark/鼓楼.png"),
            ],
            [
              require("../assets/images/landmark/壶口瀑布.png"),
              require("../assets/images/landmark/钟楼.png"),
              require("../assets/images/landmark/华清宫.png"),
            ],
            [
              require("../assets/images/landmark/大唐不夜城.png"),
              require("../assets/images/landmark/灞桥.png"),
              require("../assets/images/landmark/兵马俑2.png"),
            ],
          ],
          audioUrl: [
            [
              require("../assets/audio/西安/大雁塔.mp3"),
              require("../assets/audio/西安/大唐芙蓉园.mp3"),
              require("../assets/audio/西安/西安鼓楼.mp3"),
            ],
            [
              require("../assets/audio/西安/壶口瀑布.mp3"),
              require("../assets/audio/西安/西安钟楼.mp3"),
              require("../assets/audio/西安/华清宫.mp3"),
            ],
            [
              require("../assets/audio/西安/大唐不夜城.mp3"),
              require("../assets/audio/西安/灞桥.mp3"),
              require("../assets/audio/西安/兵马俑.mp3"),
            ],
          ],
          point: [
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
            [0.2515, 0.5063, 0.7546],
          ],
          desc: [
            [
              "大雁塔，作为现存最早、规模最大的唐代四方楼阁式砖塔，是佛塔这种古印度佛寺的建筑形式随佛教传入中原地区，并融入华夏文化的典型物证，是凝聚了中国古代劳动人民智慧结晶的标志性建筑。",
              "大唐芙蓉园，位于大雁塔东南侧，它是在原唐代芙蓉园遗址以北，仿照唐代皇家园林式样重新建造的，是中国第一个全方位展示盛唐风貌的大型皇家园林式文化主题公园，占地面积1000亩，其中水域面积300亩。",
              "西安鼓楼，建于公元1380年，是中国古代遗留下来众多鼓楼中形制最大、保存最完整的鼓楼之一。西安鼓楼建在方型基座之上，为砖木结构，总高36米，占地面积1377平方米，画栋雕梁，顶部有鎏金宝顶，是西安的标志性建筑。",
            ],
            [
              "壶口瀑布，是中国第二大瀑布，瀑布上游黄河水面宽300米，在不到500米长距离内，被压缩到20至30米的宽度，1000立方米/秒的河水，从20多米高的陡崖上倾注而泻，形成“千里黄河一壶收”的气概，故名壶口瀑布。",
              "西安钟楼，总高36米，占地面积1377平方米，位于西安市中心，是中国现存钟楼中形制最大、保存最完整的一座。始建于明太祖洪武十七年（公元1384年），明神宗万历十年（公元1582年）整体迁移于今址。",
              "华清宫，是唐代封建帝王游幸的别宫，也称“华清池”，建筑壮丽，楼台馆殿，遍布骊山上下。初名“汤泉宫”，后改名温泉宫。唐玄宗更华清宫，因在骊山，又叫骊山宫，亦称骊宫、绣岭宫。华清宫始建于唐初，鼎盛于唐玄宗执政以后。",
            ],
            [
              "大唐不夜城，以盛唐文化为背景，以唐风元素为主线，建有大雁塔北广场、玄奘广场、贞观广场、创领新时代广场四大广场，贞观之治、武后行从、开元盛世等五大文化雕塑，是西安唐文化展示和体验的首选之地。",
              '灞桥，中国历史上最古老、久负盛名而又相当宏伟的一座桥，中国古代十大名桥之一，曾是西安东去的一条必经通道。长安人送客东行多在此折柳赠别，故又名消魂桥。每逢春季团团柳絮随风飞舞，如风卷雪花，"灞柳风雪"成为关中八景之一。',
              "兵马俑，是国务院公布的第一批全国重点文物保护单位之一。1987年，秦始皇陵及兵马俑坑被联合国教科文组织批准列入《世界遗产名录》，并被誉为“世界第八大奇迹”，成为中国古代辉煌文明的一张金字名片，被誉为世界8大古墓稀世珍宝之一。",
            ],
          ],
        },
      ],
      // isPlayended: false, // 语音是否播放完毕
      timestamp: 0,
      percent: "",
      loading: false,
      progressValue: 100,
      countDown: "100%",
      player: "", // 角色实例
      game: "", // 游戏实例
      goldList: "", // 金币数组
      goldSpeed1: 0.7, // 1  -1
      goldSpeed2: -0.7, // 1  -1
      timer1: "",
      timer2: "",
      popup: "", //
      showPopup: "", // 方法: 显示景点对话框 showPopup.start()
      hiddenPopup: "", // 方法: 隐藏景点对话框 hiddenPopup.start()
      showImg01: "",
      hiddenImg01: "",
      showImg02: "",
      hiddenImg02: "",
      showImg03: "",
      hiddenImg03: "",
      hiddenLandmarkText: "",
      showLandmarkText: "",
      queryMap: "",
      queryMaplevels: "",
      bgMusic: "",
      loading: false,
      isHidden01: false,
      isHidden02: false,
      isHidden03: false,
    };
  },
  methods: {
    tweenUpdate() {
      requestAnimationFrame(this.tweenUpdate);
      this.$tweener.update();
    },

    /**
     * 初始化游戏
     */
    initGame() {
      if (this.game != "") {
        console.log("销毁游戏");
        this.game.destroy(true);
        this.game = "";
        return setTimeout(() => {
          this.initGame();
        }, 500);
      }
      console.log("currentMap:" + this.currentMap, "currentMaplevels:" + this.currentMaplevels);
      let _this = this;
      // 选择角色
      var jueseID = Number.parseInt(_this.currentRole + 1) + "";

      var game;
      // 生成游戏
      function init() {
        _this.goldList = [];
        game = new Phaser.Game(config);

        _this.game = game;
        clearInterval(_this.timer1);
        clearInterval(_this.timer2);

        _this.timer1 = setInterval(() => {
          _this.goldSpeed1 = _this.goldSpeed1 * -1;
        }, 1500);
        _this.timer2 = setInterval(() => {
          _this.goldSpeed2 = _this.goldSpeed2 * -1;
        }, 1800);

        // 点击屏幕跳跃
        setTimeout(() => {
          document.querySelector("#gameContent canvas").addEventListener("click", () => {
            _this.notifyDeviceData(0, 22, 0);
          });
          document.querySelector("#gameContent canvas").addEventListener("touchend", () => {
            _this.notifyDeviceData(0, 22, 0);
          });
        }, 1500);

        // 开启加载页面
        console.log("开启加载页面");
        _this.loading = true;
      }

      var DESIGN_WIDTH = 1334;
      var DESIGN_HEIGHT = 750;
      var PLATFORM_WIDTH = DESIGN_WIDTH;
      var PLATFORM_HEIGHT = 20;
      var PLAYER_KIKI_WIDTH = 400;
      var PLAYER_KIKI_HEIGHT = 400;
      var PLAYER_NUNA_WIDTH = 400;
      var PLAYER_NUNA_HEIGHT = 400;
      var PLAYER_POPO_WIDTH = 400;
      var PLAYER_POPO_HEIGHT = 400;
      var PLAYER_MUMU_WIDTH = 400;
      var PLAYER_MUMU_HEIGHT = 400;
      var PLAYER_NINI_WIDTH = 400;
      var PLAYER_NINI_HEIGHT = 400;
      var PLAYER_XIXI_WIDTH = 400;
      var PLAYER_XIXI_HEIGHT = 400;
      var PLAYER_DOCTORA_WIDTH = 400;
      var PLAYER_DOCTORA_HEIGHT = 400;
      var PLAYER_XIAOYOU_WIDTH = 400;
      var PLAYER_XIAOYOU_HEIGHT = 400;

      var LANGAN_BODY_WIDTH = 80;

      var BACKGROUND_ALL_SPEED = 1.6;
      // var BACKGROUND_ALL_SPEED = 16;
      var BACKGROUND1_WIDTH = 1334;
      var BACKGROUND1_HEIGHT = 750;

      var GOLD_Y_MIN = 250;
      var GOLD_Y_MAX = 450;

      let isShow01 = false;
      let isShow02 = false;
      let isShow03 = false;

      // 游戏难度
      var LEVEL = {
        speed: BACKGROUND_ALL_SPEED,
        playerRate: 18,
      };

      // 重力
      var GRAVITY = 2500;

      var config = {
        type: Phaser.AUTO,
        // type: Phaser.CANVAS,
        // type: Phaser.WebGL,
        width: DESIGN_WIDTH,
        height: DESIGN_HEIGHT,
        // scaleMode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        parent: "gameContent",
        physics: {
          default: "arcade",
          arcade: {
            // gravity: { y: 10000 },
            // debug: true,
          },
        },
        fps: {
          target: 60,
          forceSetTimeOut: true,
        },
        scene: {
          preload: preload,
          create: create,
          update: update,
        },
      };

      var player;
      var score = 0;
      var back1;
      var back2;
      var back3;
      var back4;
      var back5;
      var back6;
      var img01;
      var img02;
      var img03;
      var bgMusic;
      var runMusic;
      var landmarkAudio01;
      var landmarkAudio02;
      var landmarkAudio03;
      var title;
      var timer;
      var landmarkText;
      var popup;
      var role;
      var roleW;
      var roleH;
      var platform;
      var goldGroup;
      // var goldImageArr = ["gold", "apple", "banner"]; // 金币图片数组
      var goldImageArr = [
        "01_score10",
        "02_score20",
        "03_score20",
        "04_score20",
        "05_score20",
        "06_score20",
        "07_score30",
        "08_score30",
        "09_score30",
      ]; // 金币图片数组
      let SCORED_W_01 = 160;
      let SCORED_H_01 = 160;
      let SCORED_W_02 = 121;
      let SCORED_H_02 = 160;
      let SCORED_W_03 = 100;
      let SCORED_H_03 = 200;
      let SCORED_W_04 = 140;
      let SCORED_H_04 = 200;
      let SCORED_W_05 = 130;
      let SCORED_H_05 = 200;
      let SCORED_W_06 = 200;
      let SCORED_H_06 = 200;
      let SCORED_W_07 = 200;
      let SCORED_H_07 = 130;
      let SCORED_W_08 = 180;
      let SCORED_H_08 = 160;
      let SCORED_W_09 = 140;
      let SCORED_H_09 = 200;
      var goldSetting;
      var goldList;
      var gold;
      var playerJumping = true;

      var bgstatus = "bg1";
      var BACKGROUND1_WIDTH = 2860;
      var BACKGROUND1_HEIGHT = 750;
      var BACKGROUND2_WIDTH = 2860;
      var BACKGROUND2_HEIGHT = 750;
      var BACKGROUND3_WIDTH = 2860;
      var BACKGROUND3_HEIGHT = 750;

      function preload() {
        // 图片

        let arr = _this.mapList[_this.currentMap].url[_this.currentMaplevels];
        this.load.image("back1", arr[0]);
        this.load.image("back2", arr[1]);
        this.load.image("back3", arr[2]);
        this.load.image("back4", arr[3]);
        this.load.image("back5", arr[4]);
        this.load.image("back6", arr[5]);

        // 弹窗
        this.load.image("popup", require("../assets/images/dialog.png"));

        // 景点缩略图
        arr = _this.landmarkList.img;
        for (let i = 1; i <= arr.length; i++) {
          const element = arr[i - 1];
          this.load.image("img0" + i, element);
        }
        // 景点语音介绍
        arr = _this.landmarkHistory[_this.currentMap].audioUrl[_this.currentMaplevels];
        this.load.audio("audio01", arr[0]);
        this.load.audio("audio02", arr[1]);
        this.load.audio("audio03", arr[2]);
        // 背景音乐
        this.load.audio("bgMusic", require("../assets/audio/快乐小镇.mp3"));
        // 跑步音乐
        this.load.audio("runMusic", require("../assets/audio/run.mp3"));

        // 得分道具
        this.load.image("01_score10", require("../assets/images/01_score10.png"));
        this.load.image("02_score20", require("../assets/images/02_score20.png"));
        this.load.image("03_score20", require("../assets/images/03_score20.png"));
        this.load.image("04_score20", require("../assets/images/04_score20.png"));
        this.load.image("05_score20", require("../assets/images/05_score20.png"));
        this.load.image("06_score20", require("../assets/images/06_score20.png"));
        this.load.image("07_score30", require("../assets/images/07_score30.png"));
        this.load.image("08_score30", require("../assets/images/08_score30.png"));
        this.load.image("09_score30", require("../assets/images/09_score30.png"));

        // 雪碧图
        this.load.spritesheet("kiki", require("../assets/images/role/qiqi.png"), {
          frameWidth: PLAYER_KIKI_WIDTH,
          frameHeight: PLAYER_KIKI_HEIGHT,
        });
        this.load.spritesheet("nuna", require("../assets/images/role/nuna.png"), {
          frameWidth: PLAYER_NUNA_WIDTH,
          frameHeight: PLAYER_NUNA_HEIGHT,
        });
        this.load.spritesheet("popo", require("../assets/images/role/popo.png"), {
          frameWidth: PLAYER_POPO_WIDTH,
          frameHeight: PLAYER_POPO_HEIGHT,
        });
        this.load.spritesheet("mumu", require("../assets/images/role/mumu.png"), {
          frameWidth: PLAYER_MUMU_WIDTH,
          frameHeight: PLAYER_MUMU_HEIGHT,
        });
        this.load.spritesheet("nini", require("../assets/images/role/nini.png"), {
          frameWidth: PLAYER_NINI_WIDTH,
          frameHeight: PLAYER_NINI_HEIGHT,
        });
        this.load.spritesheet("xixi", require("../assets/images/role/xixi.png"), {
          frameWidth: PLAYER_XIXI_WIDTH,
          frameHeight: PLAYER_XIXI_HEIGHT,
        });
        this.load.spritesheet("doctorA", require("../assets/images/role/Aboshi.png"), {
          frameWidth: PLAYER_DOCTORA_WIDTH,
          frameHeight: PLAYER_DOCTORA_HEIGHT,
        });
        this.load.spritesheet("xiaoyou", require("../assets/images/role/xiaoyou.png"), {
          frameWidth: PLAYER_XIAOYOU_WIDTH,
          frameHeight: PLAYER_XIAOYOU_HEIGHT,
        });
      }

      function create() {
        // 创建背景1
        back1 = this.add.tileSprite(0, 0, BACKGROUND1_WIDTH, BACKGROUND1_HEIGHT, "back1");
        back1.setOrigin(0, 0);

        // 创建背景2
        back2 = this.add.tileSprite(DESIGN_WIDTH - 1, 0, BACKGROUND2_WIDTH, BACKGROUND2_HEIGHT, "back2");
        back2.setDisplayOrigin(0, 0);

        // 创建背景3
        back3 = this.add.tileSprite(DESIGN_WIDTH - 1, 0, BACKGROUND3_WIDTH, BACKGROUND3_HEIGHT, "back3");
        back3.setDisplayOrigin(0, 0);

        // 创建背景4
        back4 = this.add.tileSprite(DESIGN_WIDTH - 1, 0, BACKGROUND3_WIDTH, BACKGROUND3_HEIGHT, "back4");
        back4.setDisplayOrigin(0, 0);

        // 创建背景5
        back5 = this.add.tileSprite(DESIGN_WIDTH - 1, 0, BACKGROUND3_WIDTH, BACKGROUND3_HEIGHT, "back5");
        back5.setDisplayOrigin(0, 0);

        // 创建背景6
        back6 = this.add.tileSprite(DESIGN_WIDTH - 1, 0, BACKGROUND3_WIDTH, BACKGROUND3_HEIGHT, "back6");
        back6.setDisplayOrigin(0, 0);

        // 创建地板
        platform = this.physics.add.staticSprite(PLATFORM_WIDTH / 2, DESIGN_HEIGHT - PLATFORM_HEIGHT / 2);
        platform.setBodySize(PLATFORM_WIDTH, PLATFORM_HEIGHT);
        platform.setDisplaySize(PLATFORM_WIDTH, PLATFORM_HEIGHT);
        platform.setAlpha(0);

        // 创建金币
        goldList = [];
        goldGroup = this.physics.add.group();
        for (var i = 0; i < 4; i++) {
          goldSetting = {
            x: Phaser.Math.Between(1000, 1200) * i,
            y: Phaser.Math.Between(GOLD_Y_MIN, GOLD_Y_MAX),
          };
          createGold.apply(this, [goldSetting.x, goldSetting.y]);
        }

        // 选择角色
        var roleJumpUpFrames = {
          start: 1,
          end: 1,
        };
        var roleStandFrames = {
          start: 0,
          end: 9,
        };
        var roleRunFrames = {
          start: 2,
          end: 14,
        };
        switch (jueseID) {
          case "1":
            role = "kiki";
            roleW = PLAYER_KIKI_WIDTH / 2;
            roleH = PLAYER_KIKI_HEIGHT / 2;
            break;
          case "2":
            role = "nuna";
            roleW = PLAYER_NUNA_WIDTH / 2;
            roleH = PLAYER_NUNA_HEIGHT / 2;
            break;
          case "3":
            role = "popo";
            roleW = PLAYER_POPO_WIDTH / 2;
            roleH = PLAYER_POPO_HEIGHT / 2;
            break;
          case "4":
            role = "mumu";
            roleW = PLAYER_MUMU_WIDTH / 2;
            roleH = PLAYER_MUMU_HEIGHT / 2;
            break;
          case "5":
            role = "nini";
            roleW = PLAYER_NINI_WIDTH / 2;
            roleH = PLAYER_NINI_HEIGHT / 2;
            break;
          case "6":
            role = "xixi";
            roleW = PLAYER_XIXI_WIDTH / 2;
            roleH = PLAYER_XIXI_HEIGHT / 2;
            roleRunFrames = {
              start: 2,
              end: 10,
            };
            break;
          case "7":
            role = "doctorA";
            roleW = PLAYER_DOCTORA_WIDTH / 2;
            roleH = PLAYER_DOCTORA_HEIGHT / 2;
            roleRunFrames = {
              start: 2,
              end: 14,
            };
            break;
          case "8":
            role = "xiaoyou";
            roleW = PLAYER_XIAOYOU_WIDTH / 2;
            roleH = PLAYER_XIAOYOU_HEIGHT / 2;
            roleRunFrames = {
              start: 2,
              end: 13,
            };
            break;
        }

        // 创建人物
        player = this.physics.add.sprite(200, DESIGN_HEIGHT - PLATFORM_HEIGHT - 200, role);
        player.setSize(180, 300);
        player.setOffset(100, 80);
        player.setDisplayOrigin(0, 0);
        player.setDisplaySize(roleW, roleH);
        player.setGravity(0, GRAVITY);
        // 创建人物跑步动画
        this.anims.create({
          key: "player_run",
          frames: this.anims.generateFrameNumbers(role, roleRunFrames),
          frameRate: LEVEL["playerRate"],
          repeat: -1,
        });
        this.anims.create({
          key: "player_stand",
          frames: this.anims.generateFrameNumbers(role, roleStandFrames),
          frameRate: 1,
        });
        this.anims.create({
          key: "player_jump_up",
          frames: this.anims.generateFrameNumbers(role, roleJumpUpFrames),
          frameRate: 1,
        });
        // 人物播放跑动画
        player.anims.play("player_run");
        // 物体碰撞
        this.physics.add.collider(player, platform, playerPlatformHit, null, this);
        // 物体重叠
        this.physics.add.overlap(player, goldGroup, playerGoldHit, null, this);

        // 创建弹窗
        popup = this.add.sprite(0, 0, "popup");
        popup.setDisplayOrigin(0, 0);
        // popup.setScale(0.18); // popup.setDisplaySize(100, 100);
        popup.setAlpha(0);

        img01 = this.add.sprite(450, 360, "img01");
        img01.setDisplayOrigin(0, 0);
        img01.setScale(0.33);
        img01.setOrigin(0.5, 0.5);
        img01.setAlpha(0);

        img02 = this.add.sprite(450, 360, "img02");
        img02.setDisplayOrigin(0, 0);
        img02.setScale(0.33);
        img02.setOrigin(0.5, 0.5);
        img02.setAlpha(0);

        img03 = this.add.sprite(450, 360, "img03");
        img03.setDisplayOrigin(0, 0);
        img03.setScale(0.33);
        img03.setOrigin(0.5, 0.5);
        img03.setAlpha(0);

        bgMusic = this.sound.add("bgMusic", { loop: true });
        bgMusic.setVolume(0.25);
        _this.bgMusic = bgMusic;
        bgMusic.play();

        runMusic = this.sound.add("runMusic", { loop: true });
        runMusic.setVolume(0.6);

        landmarkAudio01 = this.sound.add("audio01");
        landmarkAudio02 = this.sound.add("audio02");
        landmarkAudio03 = this.sound.add("audio03");
        this.sound.addListener("play", () => {
          console.log("播放完毕");
          console.log(landmarkAudio01.isPaused);
        });
        console.log(landmarkAudio01.isPaused);

        // 初始化景点标题
        var textStyle = {
          font: "28px ",
          fill: "#fff",
          align: "center",
          strokeThickness: 1,
          padding: { x: 0, y: 10, left: null, right: null, top: null, bottom: null },
        };
        title = this.add.text(667, 174, "", textStyle);
        title.setOrigin(0.5, 0.5);

        // 文本介绍对象
        textStyle = {
          font: "22px",
          fill: "#000",
          fixedWidth: 380,
          maxLines: 30,
          wordWrap: { width: 380, callback: null, callbackScope: null, useAdvancedWrap: true },
          padding: { x: 0, y: 18, left: null, right: null, top: null, bottom: null },
          // backgroundColor: "#ff0",
        };
        landmarkText = this.add.text(860, 360, "", textStyle);
        landmarkText.setLineSpacing(10);
        landmarkText.setOrigin(0.5, 0.5);

        var scope = this;
        // 隐藏景点弹窗框
        _this.hiddenPopup = this.tweens.add({
          targets: [popup],
          paused: true,
          props: {
            alpha: 0,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          yoyo: false,
          repeat: 0, // -1 for infinite repeats
          onStart: onHiddenPopupStart,
          onComplete: onHiddenPopupComplete,
          onYoyo: function() {
            console.log("onYoyo");
            console.log(arguments);
          },
          onRepeat: function() {
            console.log("onRepeat");
            console.log(arguments);
          },
          callbackScope: scope,
        });

        // 显示景点弹窗框
        _this.showPopup = this.tweens.add({
          targets: [popup],
          paused: true,
          props: {
            alpha: 1,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          yoyo: false,
          repeat: 0, // -1 for infinite repeats
          onStart: onShowPopupStart,
          onComplete: onShowPopupComplete,
          onYoyo: function() {
            console.log("onYoyo");
            console.log(arguments);
          },
          onRepeat: function() {
            console.log("onRepeat");
            console.log(arguments);
          },
          callbackScope: scope,
        });

        _this.showImg01 = this.tweens.add({
          targets: img01,
          paused: true,
          props: {
            alpha: 1,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkAudio01.play();
            // 降低游戏音乐突出 介绍语音
            bgMusic.setVolume(0.15);
          },
        });
        _this.hiddenImg01 = this.tweens.add({
          targets: img01,
          paused: true,
          props: {
            alpha: 0,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkAudio01.stop();
            bgMusic.setVolume(0.25);
          },
        });
        _this.showImg02 = this.tweens.add({
          targets: img02,
          paused: true,
          props: {
            alpha: 1,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkAudio02.play();
            // 降低游戏音乐突出 介绍语音
            bgMusic.setVolume(0.15);
          },
        });
        _this.hiddenImg02 = this.tweens.add({
          targets: img02,
          paused: true,
          props: {
            alpha: 0,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            console.log("landmarkAudio02.pause()");
            landmarkAudio02.stop();
            bgMusic.setVolume(0.25);
          },
        });
        _this.showImg03 = this.tweens.add({
          targets: img03,
          paused: true,
          props: {
            alpha: 1,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkAudio03.play();
            // 降低游戏音乐突出 介绍语音
            bgMusic.setVolume(0.15);
          },
        });
        _this.hiddenImg03 = this.tweens.add({
          targets: img03,
          paused: true,
          props: {
            alpha: 0,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkAudio03.stop();
            bgMusic.setVolume(0.25);
          },
        });

        _this.showLandmarkText = this.tweens.add({
          targets: landmarkText,
          paused: true,
          props: {
            alpha: 1,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
        });
        _this.hiddenLandmarkText = this.tweens.add({
          targets: landmarkText,
          paused: true,
          props: {
            alpha: 0,
          },
          ease: "Linear",
          getActive: null,
          duration: 500,
          onComplete: () => {
            landmarkText.setText("");
          },
        });
        _this.player = player;
        _this.popup = popup;

        // 关闭加载页面
        console.log("关闭加载页面");
        runMusic.play();
        _this.loading = false;
      }

      function update() {
        // console.log(back1.tilePositionX);
        if (_this.currentSetp != 3) {
          player.anims.play("player_stand");
          player.anims.pause();
          runMusic.pause();

          return;
        }

        // 游戏暂停
        // console.log(
        //   "isHidden01: ",
        //   _this.isHidden01,
        //   " isHidden02: ",
        //   _this.isHidden02,
        //   " isHidden03: ",
        //   _this.isHidden03
        // );
        if (_this.gameIsOver) {
          if (landmarkAudio01.isPlaying == true && _this.isHidden01 == false) {
            if (landmarkAudio01.seek > landmarkAudio01.totalDuration - 0.5) {
              _this.isHidden01 = true;
              setTimeout(_this.ended, 2000);
              // _this.ended();
            }
          }
          if (landmarkAudio02.isPlaying == true && _this.isHidden02 == false) {
            if (landmarkAudio02.seek > landmarkAudio02.totalDuration - 0.5) {
              _this.isHidden02 = true;
              setTimeout(_this.ended, 2000);
              // _this.ended();
            }
          }
          if (landmarkAudio03.isPlaying == true && _this.isHidden03 == false) {
            if (landmarkAudio03.seek > landmarkAudio03.totalDuration - 0.5) {
              _this.isHidden03 = true;
              setTimeout(_this.ended, 2000);
              // _this.ended();
            }
          }

          return;
        }

        // 计算剩余时间
        let x = setDuration();
        let flag = true;
        if (_this.index == 0) {
          flag = isShow01;
        } else if (_this.index == 1) {
          flag = isShow02;
        } else if (_this.index == 2) {
          flag = isShow03;
        }
        // console.log(x > _this.point, flag);
        if (x > _this.point && !flag) {
          console.log("到达景点");
          if (_this.index == 0) {
            isShow01 = true;
          } else if (_this.index == 1) {
            isShow02 = true;
          } else if (_this.index == 2) {
            isShow03 = true;
          }
          _this.showPopup.play();
          _this.showLandmarkText.play();
          _this["showImg0" + (_this.index + 1)].play();
        }

        // 检测到体感装置跳起动作
        if (_this.deviceData.acceleration > 10) {
          if (!playerJumping) {
            player.setVelocity(0, JUMP_FORCE);
            player.anims.play("player_jump_up");
            runMusic.pause();
            _this.playUpMusice();
            playerJumping = true;
          }
        }

        // 游戏速度
        var speed = LEVEL["speed"];

        // 移动背景
        if (bgstatus === "bg1") {
          back1.setTilePosition(back1.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg2") {
          back2.setTilePosition(back2.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg3") {
          back3.setTilePosition(back3.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg4") {
          back4.setTilePosition(back4.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg5") {
          back5.setTilePosition(back5.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg6") {
          back6.setTilePosition(back6.tilePositionX + BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg1CrossBg2") {
          back1.setPosition(back1.x - BACKGROUND_ALL_SPEED, 0);
          back2.setPosition(back2.x - BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg2CrossBg3") {
          back2.setPosition(back2.x - BACKGROUND_ALL_SPEED, 0);
          back3.setPosition(back3.x - BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg3CrossBg4") {
          back3.setPosition(back3.x - BACKGROUND_ALL_SPEED, 0);
          back4.setPosition(back4.x - BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg4CrossBg5") {
          back4.setPosition(back4.x - BACKGROUND_ALL_SPEED, 0);
          back5.setPosition(back5.x - BACKGROUND_ALL_SPEED, 0);
        } else if (bgstatus === "bg5CrossBg6") {
          back5.setPosition(back5.x - BACKGROUND_ALL_SPEED, 0);
          back6.setPosition(back6.x - BACKGROUND_ALL_SPEED, 0);
        }

        // 背景移动状态切换判断
        if (bgstatus === "bg1" && back1.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          bgstatus = "bg1CrossBg2";
          back2.setTilePosition(0, 0);
          back2.setPosition(DESIGN_WIDTH - 1, 0);
        } else if (bgstatus === "bg1CrossBg2" && back2.x <= 0) {
          // 销毁地图
          back1.destroy(true);
          bgstatus = "bg2";
          back2.setPosition(0, 0);
        } else if (bgstatus === "bg2" && back2.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          bgstatus = "bg2CrossBg3";
          back3.setTilePosition(0, 0);
          back3.setPosition(DESIGN_WIDTH - 1, 0);
        } else if (bgstatus === "bg2CrossBg3" && back3.x <= 0) {
          // 销毁地图
          back2.destroy(true);
          bgstatus = "bg3";
          back3.setPosition(0, 0);
        } else if (bgstatus === "bg3" && back3.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          bgstatus = "bg3CrossBg4";
          back4.setTilePosition(0, 0);
          back4.setPosition(DESIGN_WIDTH - 1, 0);
        } else if (bgstatus === "bg3CrossBg4" && back4.x <= 0) {
          // 销毁地图
          back3.destroy(true);
          bgstatus = "bg4";
          back4.setPosition(0, 0);
        } else if (bgstatus === "bg4" && back4.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          bgstatus = "bg4CrossBg5";
          back5.setTilePosition(0, 0);
          back5.setPosition(DESIGN_WIDTH - 1, 0);
        } else if (bgstatus === "bg4CrossBg5" && back5.x <= 0) {
          // 销毁地图
          back4.destroy(true);
          bgstatus = "bg5";
          back5.setPosition(0, 0);
        } else if (bgstatus === "bg5" && back5.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          bgstatus = "bg5CrossBg6";
          back6.setTilePosition(0, 0);
          back6.setPosition(DESIGN_WIDTH - 1, 0);
        } else if (bgstatus === "bg5CrossBg6" && back6.x <= 0) {
          // 销毁地图
          back5.destroy(true);
          bgstatus = "bg6";
          back5.setPosition(0, 0);
        } else if (bgstatus === "bg6" && back6.tilePositionX >= BACKGROUND1_WIDTH - DESIGN_WIDTH) {
          // 结束游戏
          _this.currentSetp = 4;
          _this.pauseRunMusice();
          return;
        }

        // 金币移动
        for (var i = 0; i < goldList.length; i++) {
          if (goldList[i].x <= -LANGAN_BODY_WIDTH) {
            // 金币回收
            goldList[i].setAlpha(1);
            goldList[i].setPosition(PLATFORM_WIDTH, Phaser.Math.Between(GOLD_Y_MIN, GOLD_Y_MAX));
          } else {
            // 金币移动
            let goldSpeed;
            if (i < goldList.length / 2) {
              goldSpeed = _this.goldSpeed1;
            } else {
              goldSpeed = _this.goldSpeed2;
            }
            if (i % 2 == 0) {
              goldList[i].setPosition(goldList[i].x - speed, goldList[i].y + goldSpeed);
            } else {
              goldList[i].setPosition(goldList[i].x - speed, goldList[i].y - goldSpeed);
            }
          }
        }

        // console.log(bgstatus);

        // 移动距离增加分数
      }

      /**
       * 显示景点介绍弹框 start
       */
      function onShowPopupStart() {
        // console.log("onShowPopupStart");
        // console.log(arguments);
      }
      /**
       * 显示景点介绍弹框 end
       */
      function onShowPopupComplete() {
        console.log("onShowPopupComplete");
        // console.log(arguments);
        _this.gameIsOver = true;
        // 关闭跑步音乐
        runMusic.pause();

        // 播放语音介绍
        _this.player.anims.play("player_stand");
        _this.player.anims.pause();

        // 修改标题
        title.setText(_this.landmarkTitle);

        // console.log(isShow01, isShow02, isShow03);
        // 初始化文字描述
        typelt(landmarkText);
      }

      /**
       * 隐藏景介绍弹框 start
       */
      function onHiddenPopupStart() {
        console.log("onHiddenPopupStart");
        // console.log(arguments);
      }
      /**
       * 隐藏景介绍弹框 end
       */
      function onHiddenPopupComplete() {
        console.log("onHiddenPopupComplete");
        // console.log(arguments);
        _this.gameIsOver = false;
        // 开启跑步音乐
        runMusic.play();
        // 开启跑步动画
        _this.index++;
        // 清空打字效果
        clearInterval(timer);
        // 清空title
        title.setText("");
        landmarkText.setText("");
      }

      /**
       * 更新景点文本介绍
       */
      function typelt(text) {
        let counter = 0;
        var cursor = "| ";
        let timer = setInterval(() => {
          if (!_this.gameIsOver) return clearInterval(timer);
          _this.landmarkText = _this.befoLandmark.substr(0, counter) + cursor;
          text.setText(_this.befoLandmark.substr(0, counter) + cursor);
          counter++;
          if (counter === _this.landmarkText.length + 1) {
            clearInterval(timer);
          }
        }, 185);
      }

      /**
       * 同步倒计时, 返回走过地图的百分比
       */
      function setDuration() {
        let duration; // 总时长
        let totalLenght = BACKGROUND1_WIDTH * 6; // 地图总长度
        let a = 0; // 已走过的地图长度
        let percentage; // 百分比
        if (bgstatus == "bg1") {
          a = back1.tilePositionX;
        } else if (bgstatus == "bg1CrossBg2") {
          a = BACKGROUND1_WIDTH - back2.x;
        } else if (bgstatus == "bg2") {
          a = BACKGROUND1_WIDTH + back2.tilePositionX;
        } else if (bgstatus == "bg2CrossBg3") {
          a = BACKGROUND1_WIDTH * 2 - back3.x;
        } else if (bgstatus == "bg3") {
          a = BACKGROUND1_WIDTH * 2 + back3.tilePositionX;
        } else if (bgstatus == "bg3CrossBg4") {
          a = BACKGROUND1_WIDTH * 3 - back4.x;
        } else if (bgstatus == "bg4") {
          a = BACKGROUND1_WIDTH * 3 + back4.tilePositionX;
        } else if (bgstatus == "bg4CrossBg5") {
          a = BACKGROUND1_WIDTH * 4 - back5.x;
        } else if (bgstatus == "bg5") {
          a = BACKGROUND1_WIDTH * 4 + back5.tilePositionX;
        } else if (bgstatus == "bg5CrossBg6") {
          a = BACKGROUND1_WIDTH * 5 - back6.x;
        } else if (bgstatus == "bg6") {
          a = BACKGROUND1_WIDTH * 5 + back6.tilePositionX;
        } else if (bgstatus == "bg6CrossBg1") {
          a = totalLenght - DESIGN_WIDTH;
        }
        // 百分比
        percentage = 100 - Number.parseInt((a / (totalLenght - DESIGN_WIDTH)) * 100);
        duration = Number.parseInt((totalLenght - a - DESIGN_WIDTH) / BACKGROUND_ALL_SPEED / 60);
        _this.progressValue = percentage;
        let m = Math.floor(duration / 60) < 0 ? 0 : Math.floor(duration / 60);
        let mm = m < 10 ? "0" + m : m;
        let ss = duration % 60 < 10 ? "0" + Number.parseInt(duration % 60) : Number.parseInt(duration % 60);
        _this.countDown = percentage + "%";

        return a;
      }

      // 获取随机数
      function random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }

      // 创建金币
      function createGold(x, y) {
        let goldInd = random(0, goldImageArr.length);
        let goldW, goldH;

        if (goldInd == 0) {
          goldW = SCORED_W_01;
          goldH = SCORED_H_01;
        } else if (goldInd == 1) {
          goldW = SCORED_W_02;
          goldH = SCORED_H_02;
        } else if (goldInd == 2) {
          goldW = SCORED_W_03;
          goldH = SCORED_H_03;
        } else if (goldInd == 3) {
          goldW = SCORED_W_04;
          goldH = SCORED_H_04;
        } else if (goldInd == 4) {
          goldW = SCORED_W_05;
          goldH = SCORED_H_05;
        } else if (goldInd == 5) {
          goldW = SCORED_W_06;
          goldH = SCORED_H_06;
        } else if (goldInd == 6) {
          goldW = SCORED_W_07;
          goldH = SCORED_H_07;
        } else if (goldInd == 7) {
          goldW = SCORED_W_08;
          goldH = SCORED_H_08;
        } else if (goldInd == 8) {
          goldW = SCORED_W_09;
          goldH = SCORED_H_09;
        }
        gold = goldGroup.create(x, y, goldImageArr[goldInd]);
        gold.setDisplayOrigin(0, 0);
        gold.setDisplaySize(goldW / 2, goldH / 2);
        goldList.push(gold);
        _this.goldList = goldList;
      }

      // 玩家和地面碰撞回调
      function playerPlatformHit(player, platform) {
        if (playerJumping && !_this.gameIsOver) {
          runMusic.play();

          playerJumping = false;
          _this.deviceData.acceleration = 0;
          player.anims.play("player_run");
        }
      }

      // 玩家与金币碰撞
      function playerGoldHit(player, gold) {
        if (gold.alpha == 1) {
          gold.setAlpha(0);
          // 判断奖励类型
          if (gold.texture.key === "01_score10") {
            fenshu.call(this, 1);
          } else if (gold.texture.key === "02_score20") {
            fenshu.call(this, 2);
          } else if (gold.texture.key === "03_score20") {
            fenshu.call(this, 2);
          } else if (gold.texture.key === "04_score20") {
            fenshu.call(this, 2);
          } else if (gold.texture.key === "05_score20") {
            fenshu.call(this, 2);
          } else if (gold.texture.key === "06_score20") {
            fenshu.call(this, 2);
          } else if (gold.texture.key === "07_score30") {
            fenshu.call(this, 3);
          } else if (gold.texture.key === "08_score30") {
            fenshu.call(this, 3);
          } else if (gold.texture.key === "09_score30") {
            fenshu.call(this, 3);
          }
          // sourceSet["jiangli.mp3"].muted = false;
          // sourceSet["jiangli.mp3"].currentTime = 0;
          // sourceSet["jiangli.mp3"].play();
        }
      }

      // 加分计算
      function fenshu(ind) {
        _this.score += ind;
        if (score < 0) {
          score = 0;
        }
        _this.playScoreMusice();

        // 分数效果
        var fsize = 48 + "px";
        var text = this.add.text(player.x, player.y, "+" + ind, {
          fontSize: fsize,
          color: "#FA6400",
        });
        var offsetY = 50 + ind;
        this.tweens.add({
          targets: text,
          duration: 1000,
          alpha: 0,
          y: text.y - offsetY,
          ease: "Power2",
          onComplete: function() {
            text.destroy();
          },
        });
      }

      init();
    },

    // 开始游戏
    play() {
      console.log("play()");
      lastTime = +new Date();
      this.score = 0;
      if (this.currentMaplevels == 0) {
        this.maxScore = 20;
      } else if (this.currentMaplevels == 1) {
        this.maxScore = 35;
      } else if (this.currentMaplevels == 2) {
        this.maxScore = 50;
      }
    },
    // 游戏结束
    resetGame() {
      console.log("游戏结束: resetGame()");
      this.setGameData();
    },
    // 资源加载完毕
    onLoad() {
      this.$refs.palyBtn.style.visibility = "visible";
    },
    // 设置用户数据
    syncUserData(userId, userName, enviroment = "mobile") {
      this.userInfo = {
        userId,
        userName,
        enviroment,
      };

      // 判断设备
      if (enviroment == "mobile") {
        // 移动端按钮样式蓝色
        var head = document.getElementsByTagName("head")[0];
        var link = document.createElement("link");
        link.href = "/mobil.css";
        link.rel = "stylesheet";
        link.type = "text/css";
        head.appendChild(link);
      }
    },
    // 获取运动数据的回调
    notifyDeviceData(speed, acceleration, height) {
      // console.log("notifyDeviceData");
      if (acceleration > 10 && this.gameIsOver) {
        let timestamp = +new Date();
        console.log(timestamp - this.timestamp);
        if (timestamp - this.timestamp > 1500 && timestamp - this.timestamp < 3000) {
          this.ended();
          return;
        } else if (timestamp - this.timestamp > 3000) {
          this.timestamp = timestamp;
        }
      }

      // 播放人物跳跃动画
      if (acceleration > 16) {
        this.deviceData.acceleration = acceleration;
        // this.jump();
        JUMP_FORCE = -1300;
      } else if (acceleration > 10) {
        this.deviceData.acceleration = acceleration;
        // this.jump();
        JUMP_FORCE = -1100;
      }
    },
    // 获取传感器连接状态的回调
    notifyDeviceStatus(status) {
      this.deviceStatus = status;
    },

    playPreludeMusic() {
      this.$refs.preludeMusic.currentTime = 0;
      this.$refs.preludeMusic.volume = 0.2;
      this.$refs.preludeMusic.play();
    },
    pausePreludeMusic() {
      this.$refs.preludeMusic.pause();
    },
    playRunMusic() {
      console.log("runMusic");
    },
    pauseRunMusice() {
      console.log("runMusic");
    },
    playUpMusice() {
      this.$refs.upMusic.volume = 0.2;
      this.$refs.upMusic.play();
    },
    pauseUpMusice() {
      this.$refs.upMusic.pause();
    },
    playScoreMusice() {
      this.$refs.scoreMusic.volume = 0.2;
      this.$refs.scoreMusic.play();
    },
    pauseScoreMusice() {
      this.$refs.scoreMusic.pause();
    },

    // 语音介绍完毕 关闭对话框
    ended() {
      if (this.index == 0) {
        this.isHidden01 = true;
      } else if (this.index == 1) {
        this.isHidden02 = true;
      } else if (this.index == 2) {
        this.isHidden03 = true;
      }
      console.log("ended");
      // 关闭景点介绍弹框
      this.gameIsOver = false;
      this.hiddenPopup.play();
      this.hiddenLandmarkText.play();
      this["hiddenImg0" + (this.index + 1)].play();
    },

    /**
     * 预加载图片
     */
    preload() {
      let count = 0;
      let length = imgPreloaderList.length;
      for (let img of imgPreloaderList) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          count++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((count / length) * 100);
          if (percentNum == 100) {
            setTimeout(() => {
              this.$refs.palyBtn.style.visibility = "visible";
              this.$nextTick(() => {
                // TV端按钮默认聚焦
                // if (this.userInfo.enviroment != "mobile") {
                // document.querySelector(".palyBtn").focus();
                setTimeout(() => {
                  console.log(this.$refs.palyBtn);
                  this.$refs.palyBtn.focus();
                }, 100);
                // }
              });
            }, 3000);
          }
          this.percent = `${percentNum}%`;
          // console.log(this.percent);
        };
      }
    },

    /**
     * 解锁地图下一个关卡
     */
    next(flag) {
      console.log("刷新");
      let currentMap = this.currentMap;
      let currentMaplevels = this.currentMaplevels;
      let currentRole = this.currentRole;

      if (flag) {
        currentMaplevels = currentMaplevels - 0 + 1;
      }
      history.replaceState(
        null,
        "page1",
        `?currentMap=${currentMap}&currentMaplevels=${currentMaplevels}&currentRole=${currentRole}`
      );
      window.location.reload();

      return;
    },
    /**
     * 选择地图
     */
    selectMap() {
      if (!this.currentSetp) return;
      this.currentMap = this.valueMap;
    },
    /**
     * 地图加载完毕的回调
     */
    imgLoad() {
      if (this.loading) {
        // console.log("地图加载完毕");
        this.currentSetp = 3;
        this.initGame();
      }
    },
    loadingHandle() {
      if (this.queryMap === "" && this.valueMap === "") return;
      this.selectMap();
      console.log("loadingHandle");
      this.loading = true;
      this.imgLoad();
      this.currentSetp = 3;
    },
    /**
     * toAGame()
     */
    toAGame() {
      history.replaceState(null, "page1", "?");
      window.location.reload();
    },
    setcurrentSetp(value) {
      this.valueMap = value;
      this.currentMaplevels = 0;
    },
    selectRole(value, flag) {
      this.currentRole = value;

      if (!flag) return;
      if (this.queryMap !== "") {
        this.currentSetp = this.currentSetp + 2;
        this.loadingHandle();
      } else {
        this.currentSetp = this.currentSetp + 1;
      }
    },
    setQueryMap() {
      let queryObj = {};
      let index = window.location.href.indexOf("?");
      let str = window.location.href.slice(index + 1);
      if (index == -1) return;
      let arr1 = str.split("&");
      for (const key in arr1) {
        const item = arr1[key];
        let arr2 = item.split("=");
        queryObj[arr2[0]] = arr2[1];
      }
      let { currentMap, currentMaplevels, currentRole } = queryObj;

      console.log(queryObj);
      if (currentMap !== undefined) {
        if (!["0", "1", "2"].includes(currentMap)) {
          currentMap = 0;
        }

        this.queryMap = currentMap;
        this.valueMap = currentMap;
        this.currentMap = currentMap;
        if (currentMaplevels !== undefined) {
          if (!["0", "1", "2"].includes(currentMaplevels)) {
            currentMaplevels = 0;
          }
          this.queryMaplevels = currentMaplevels;
          this.currentMaplevels = currentMaplevels;
        }

        if (currentRole !== undefined) {
          if (!["0", "1", "2", "3", "4", "5", "6", "7"].includes(currentRole)) {
            this.currentRole = 0;
          } else {
            this.currentRole = Number.parseInt(currentRole);
          }
        }
      }
    },

    /**
     * 排行榜翻页
     */
    setPage(str) {
      if (str == "prev") {
        if (this.page - 1 < 0) {
          this.page = 0;
        } else {
          this.page--;
        }
      }
      if (str == "next") {
        if (this.gameRecordList.slice((this.page + 1) * 5).length != 0) {
          this.page++;
        }
      }
    },
    /**
     * 提交本次游戏数据
     */
    async setGameData() {
      this.gameRecord = {
        avatar: this.role.avatar,
        userName: this.userInfo.userName,
        gameRecor: this.score,
        NO: "",
      };

      // 存储本次游戏数据
      let url = "https://st.toasin.cn/api/Szx/save";
      let params = {
        authkey: "",
        gameName: "中国行",
        gameRecor: this.score,
        gameRole: this.role.name,
        userId: this.userInfo.userId,
        userName: this.userInfo.userName,
      };
      let result = await axios.post(url, params);
      console.log(result.data);
      // const response = await fetch(url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json;charset=utf-8",
      //   },
      //   body: JSON.stringify(params),
      // });
      // let result = await response.json();
      // console.log(result);

      this.getGameData();
    },
    /**
     * 更新排行榜数据
     */
    async getGameData() {
      let url = "https://st.toasin.cn/api/Szx/index";
      let params = {
        authkey: "",
        gameName: "中国行",
        userId: this.userInfo.userId,
        userName: this.userInfo.userName,
      };
      let result = await axios(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        params: params,
      });
      let data = result.data.data;
      console.log(data);

      let arr = data;
      this.gameRecordList = arr.map((item, i) => {
        const index = this.roleList.findIndex((item1) => item1.name == item.game_role);
        item.avatar = this.roleList[index].avatar;
        item.NO = i + 1;
        if (item.userid == this.userInfo.userId) {
          this.gameRecord.NO = item.NO;
        }
        return item;
      });
    },
    playGame() {
      if (this.queryMap !== "") {
        this.currentSetp = 3;
        this.initGame();
      } else {
        this.currentSetp = 1;
      }
    },
  },
  watch: {
    currentSetp(value, oldValue) {
      // console.log("currentSetp:", value);
      switch (value) {
        case 0:
          break;
        case 1:
          this.playPreludeMusic();
          this.$nextTick(() => {
            console.log("默认聚焦第一个角色");
            this.$refs.item[0].focus();
          });
          break;
        case 2:
          break;

        case 3:
          this.playUpMusice();
          this.pauseUpMusice();
          this.pausePreludeMusic();
          this.playScoreMusice();
          this.pauseScoreMusice();
          this.play();
          this.pausePreludeMusic();
          this.playRunMusic();
          break;
        case 4:
          console.log("地图耗时:" + (+new Date() - lastTime));
          this.resetGame();
          this.pauseUpMusice();
          break;
        case 5:
          this.bgMusic.pause();
          this.playPreludeMusic();
          break;
        default:
          break;
      }

      switch (oldValue) {
        case 4:
          this.index = 0;
          break;
      }
    },
    gameIsOver(value, oldValue) {
      if (value == false && oldValue == true) {
        this.player.anims.play("player_run");
      }
    },
  },
  computed: {
    landmarkList() {
      let result = {};
      let data = this.landmarkHistory[this.currentMap];
      // console.log(data);
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          result[key] = element[this.currentMaplevels];
        }
      }
      // console.log(result);
      return result;
    },
    point() {
      // 1-6
      let result =
        this.landmarkHistory[this.currentMap].point[this.currentMaplevels][this.index] *
        ((this.map.width / this.map.height) * this.clientHeight);
      result < (this.map.width / this.map.height) * this.clientHeight
        ? result
        : (this.map.width / this.map.height) * this.clientHeight - 100;
      console.log(result);
      return result;
    },
    showGameRecord() {
      let pageSize = 5;
      let result = this.gameRecordList.slice(this.page * pageSize, (this.page + 1) * pageSize);
      return result;
    },
    // 1-7
    landmarkTitle() {
      return this.landmarkHistory[this.currentMap].title[this.currentMaplevels][this.index];
    },

    befoLandmark() {
      return this.landmarkHistory[this.currentMap].desc[this.currentMaplevels][this.index];
    },
    role() {
      return this.roleList[this.currentRole];
    },
    map() {
      let data = this.mapList[this.currentMap];
      let result = {};

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if (key == "url" || key == "width" || key == "height") {
            result[key] = element[this.currentMaplevels];
          } else {
            result[key] = element;
          }
        }
      }

      return result;
    },
    countWidth() {
      let result = 40 + this.countDown.slice(0, -1) * 2.06 + "px";
      return result;
    },
    countDownMR() {
      let a = this.countDown.slice(0, -1);
      // if (a > 90) {
      //   a = 90;
      // }
      let result = 2.2 * a;

      return result;
    },
  },
  created() {
    /*
      挂载至全局用与 android 调用
    */
    window.syncUserData = this.syncUserData;
    window.notifyDeviceData = this.notifyDeviceData;
    window.notifyDeviceStatus = this.notifyDeviceStatus;
  },
  mounted() {
    this.tweenUpdate();
    this.preload();
    this.setQueryMap();
    checkScreen();
  },
};
</script>

<style scoped>
button {
  border: none;
}
.gameContent {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.gameContent canvas {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.bg-black {
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.37) !important; */
  background: transparent;
  z-index: 10;
}
.mask.bg-black {
  box-sizing: border-box;
  display: block;
  background-color: rgba(0, 0, 0, 0.47);
}

.bgWrap {
  box-sizing: border-box;
  position: absolute;
  width: 1334px;
  height: 750px;
  transition: all 60s initial;
  overflow: hidden;
}

.bgs {
  position: absolute;
  display: flex;
  width: 100%;
  overflow: hidden;
  z-index: -100;
}
.roleImg {
  transform: translate(-80%, -66.66%);
}

#role {
  position: absolute;
  z-index: 3;
  perspective: 1000px;
  overflow: hidden;
  transform: translateZ(0);
}
/* .role .roleUp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
} */
/* role end */
.mask {
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: #a6a6a6;
}
.mask .img-wrap {
  position: absolute;
  width: 410px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
p {
  position: absolute;
  margin: 0;
  top: 40%;
  left: 50%;
  font-size: 10vh;
  white-space: nowrap;
  color: #ccc;
  transform: translate(-50%);
}
.mask .img-wrap img {
  width: 100%;
  height: 100%;
}
.mask .img-wrap .text {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: 140px;
  color: #cf4d00;
  font-size: 28px;
}
.mask .img-wrap .btn-wrap {
  display: flex;
  justify-content: center;
}
.mask .img-wrap .recordBtn {
  margin: 20px;
  width: 160px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-size: contain;
  color: #fff;
  font-size: 24px;
  background: url("../assets/images/button.png") no-repeat center/contain;
  outline: none !important;
}
.recordBtn:hover,
.recordBtn:focus {
  background: url("../assets/images/button1.png") no-repeat center/contain !important;
}
.mask .indexBg1 {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.mask .indexRole1 {
  position: absolute;
  /* top: 21%;
  left: 29%; */
  top: 200px;
  left: 300px;
  object-fit: cover;
}
.mask .indexProp1 {
  position: absolute;
  /* top: 35%;
  left: 23.6%; */
  top: 300px;
  left: 228px;
  object-fit: cover;
  z-index: 11;
}
.mask .indexProp2 {
  position: absolute;
  top: 21.2%;
  right: 22.4%;
  object-fit: cover;
}
.mask .indexRole {
  position: absolute;
  left: 7%;
  bottom: 7%;
  perspective: 1000px;
  overflow: hidden;
}
.mask .indexRole img {
  animation: run 0.75s steps(1, start) infinite;
}

.mask .box-center {
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  z-index: 10;
  width: 48%;
  min-width: 340px;
}
.mask .box-center .title {
  color: beige;
  margin: 12px;
  height: 128px;
  cursor: pointer;
  /* background: url("../assets/images/gameName.png") no-repeat center/contain; */
}
.mask .box-center .progressBar {
  margin: 40px 0;
  width: 100%;
  height: 30px;
  border-radius: 22px;
  background-color: #a6a6a6;
  overflow: hidden;
}
.mask .selectModelWrap {
  position: relative;
  top: 0;
  left: 0;
  width: 900px;
  height: 480px;
  z-index: 10;
  margin: auto;
}

.selectModelWrap .container {
  padding: 50px 0 0;
  /* height: 80%; */
  text-align: center;
  background-color: #ffffffa8;
  border-radius: 26px;
}
.selectModelWrap .container .top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}
.selectModelWrap .container .top .title {
  height: 10vh;
}
.selectModelWrap .container .select-wrap {
  display: flex;
  margin: 0 auto;
  padding-bottom: 40px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.selectModelWrap .container .select-wrap .item {
  margin: 0 20px 30px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  /* background-color: pink; */
}

.selectModelWrap .container .select-wrap-02 .item {
  position: relative;
}
.selectModelWrap .container .select-wrap-02 .item > .bgimg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  transform: translate(-50%, -50%);
  filter: saturate(0.3);
}
.selectModelWrap .container .select-wrap-02 .item.active > .bgimg {
  filter: saturate(1);
}
.selectModelWrap .container .select-wrap-02 .item a img {
  padding: 15%;
  width: 60%;
  height: 60%;
  filter: saturate(1);
}
.selectModelWrap .container .select-wrap .item .role-name {
  position: absolute;
  left: 50%;
  bottom: 0;
  color: #666;
  font-size: 24px;
  transform: translate(-50%, 55%);
}
.selectModelWrap .container .select-wrap .item .role-name.active {
  /* color: #f76520; */
  color: #2a71f5;
}

.selectModelWrap .container .select-wrap-02 .item img.bgimg {
  transition: all 0.8s;
}
.selectModelWrap .container .select-wrap-02 .item.active {
  /* transform: scale(1.1); */
  filter: saturate(1);
}
.selectModelWrap .container .select-wrap-02 .item.active img {
  transform: scale(1.1);
}
.selectModelWrap .container .select-wrap-02 .item.active img.bgimg {
  transform: translate(-50%, -50%) scale(1.1);
}

.mask .selectModelWrap .hd-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: rgba(80, 80, 80, 1);
  text-align: center;
  border-radius: 16px;
  border: 1px solid #cf4d00;
  background-color: #fbec72;
}
.mask .selectModelWrap .hd-box.style-02 {
  border: none;
  background-color: #ffffffc4;
}
.mask .selectModelWrap .hd-box .top {
  /* padding: 6vh; */
  font-family: font-medium;
  /* font-size: 6vh; */
  text-align: center;
}
.mask .selectModelWrap .hd-box.style-02 .top {
  padding: 0;
}
.mask .selectModelWrap .hd-box.style-02 .top .title {
  height: 10vh;
  transform: translateY(-50%);
}
.mask .selectModelWrap .hd-box .model {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  padding: 10px 18px;
  /* justify-content: space-around;
  height: calc(100% - 61px); */
}
.mask .selectModelWrap .hd-box .model .item {
  display: flex;
  flex-direction: column;
  border: none;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.mask .selectModelWrap .hd-box.style-02 .model .item {
  width: 240px;
  height: 300px;
}
.hd-box .model .item .btn {
  width: 100%;
  height: 100%;
  background-color: pink;
  object-fit: cover;
}
.hd-box .model .item .score {
  min-width: 70px;
}
.hd-box .model .item .btn {
  border: 4px solid #fff !important;
}
.hd-box .model .item .btn.active {
  border: 4px solid #ff8d1a !important;
}
.hd-box .model .item img {
  width: 100%;
  height: 100%;
}

.mask .selectModelWrap .hb-box {
  display: flex;
  /* padding: 20px; */
  margin-top: 40px;
  justify-content: center;
  align-content: center;
}
.mask .selectModelWrap .hb-box .btn {
  width: 160px;
  height: 60px;
  border-radius: 16px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  background-size: contain;
  font-size: 24px;
  border: none;
}

.progressBar .tag {
  width: 0;
  height: 100%;
  border-radius: 22px;
  background-color: #ffc300;
  transition: width 3s ease-out;
}

.mask .box-center .palyBtn {
  margin: 30px auto 30px auto;
  width: 175px;
  height: 74px;
  text-align: center;
  color: #fff;
  line-height: 70px;
  font-size: 24px;
  background-size: contain;
  /* background: url("../assets/images/btn1.png") no-repeat center/100%; */
  visibility: hidden;
}

.button1 {
  position: absolute;
  top: 0;
  left: 0px;
  width: 200px;
  height: 80px;
  line-height: 80px;
}
.button2 {
  position: absolute;
  top: 0;
  left: 220px;
  width: 200px;
  height: 80px;
  line-height: 80px;
}
.button3 {
  position: absolute;

  width: 200px;
  height: 80px;
  line-height: 80px;
}
.rankingList-wrap {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 80px 190px 60px;
  width: 90%;
  z-index: 10; */
}
/* .rankingList-wrap .hd-box {
  padding-top: 70px;
  display: flex;
} */
.rankingList-wrap .hd-box .left {
  position: absolute;
  left: 168px;
  top: 147px;
  box-sizing: border-box;
  position: relative;
  width: 728px;
  height: 478px;
  padding: 24px 0;
  border: 4px solid #cf4d00;
  border-radius: 36px;
  background-color: #fbec72;
  overflow: hidden;
}
.rankingList-wrap .hd-box .left .title {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 27%;
  color: #51596e;
  text-shadow: 1px 1px 2px #51596e;
  transform: translate(-50%, -120%);
}

.rankingList-wrap .hd-box .left .list-item {
  height: calc(100% - 55px);
  font-size: 24px;
}
.rankingList-wrap .hd-box .left .list .item {
  display: flex;
  justify-content: flex-start;
  color: #ff8d1a;
}
.rankingList-wrap .hd-box .left .list .item .avatar {
  position: relative;
  top: 0;
  left: 0;
}
.rankingList-wrap .hd-box .left .list .item .avatar .tag {
  position: absolute;
  top: 0%;
  left: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  transform: translate(25%, -30%);
  background-color: transparent;
}
.rankingList-wrap .hd-box .left .list.list-hd .item {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  color: #51596e;
  font-size: 26px;
}
.rankingList-wrap .content .name {
  padding: 5px 0 5px 0;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rankingList-wrap .hd-box .left .list .item .ranking {
  width: 20%;
  color: #d05d2f;
  font-weight: bold;
  font-size: 26px;
}
.rankingList-wrap .hd-box .left .list .item .avatar {
  width: 15%;
  color: #d05d2f;
}
.rankingList-wrap .hd-box .left .list .item .name {
  width: 30%;
  color: #d05d2f;
}
.rankingList-wrap .hd-box .left .list .item .score {
  width: 20%;
  color: #d05d2f;
}
.rankingList-wrap .hd-box .left .list .item div {
  padding: 5px 0px;
  font-size: 22px;
  line-height: 2;
  text-align: center;
}
.rankingList-wrap .hd-box .left .list .item .avatar {
  width: 15%;
}
.rankingList-wrap .hd-box .left .list .item .name {
  width: 35%;
}
.rankingList-wrap .hd-box .left .list .item .score {
  width: 15%;
}
.rankingList-wrap .hd-box .left .list .item img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
}
.rankingList-wrap .hd-box .right {
  position: absolute;
  left: 952px;
  top: 147px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 36px 0;
  width: 220px;
  height: 480px;
  border-radius: 36px;
  border: 4px solid #cf4d00;
  background-color: #fbec72;
  font-size: 24px;
}
.rankingList-wrap .hd-box .right .myAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
}
.rankingList-wrap .hd-box .right .myName {
  white-space: normal;
  margin-bottom: 5px;
  color: #cf5d2f;
  font-size: 28px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rankingList-wrap .hd-box .right .myLabel {
  font-size: 32px;
  color: white;
  text-align: center;
  line-height: 40px;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 70%);
}
.rankingList-wrap .hd-box .right .myScore {
  color: #ff8d1a;
  font-size: 35px;
}
.rankingList-wrap .hd-box .right .myRanking {
  font-size: 35px;
  color: #ff8d1a;
}
.rankingList-wrap .hb-box {
  position: absolute;
  top: 647px;
  left: 178px;
  width: 740px;
  text-align: center;
}
.rankingList-wrap .hb-box .toPalyBtn {
  margin: 0 10px;
  width: 220px;
  color: #fff;
  font-size: 28px;
  background-size: contain;
  border-radius: 22px;
}
/* .flex {
  flex: 1;
} */
.header-fixed {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 0;
  padding: 20px 0px 20px 40px;
  width: 100%;
}
.header-fixed .left .content {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px 50px 10px 50px;
  /* width: 170px; */
  color: #ffeb3b;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.37);
}
.header-fixed .left .content .avatar {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
.header-fixed .midden {
  position: relative;
  box-sizing: border-box;
  height: 42px;
  width: 100%;
}
.header-fixed .midden .content {
  box-sizing: border-box;
  position: absolute;
  display: inline-block;
  width: calc(100% - 80px);
  background: rgba(231, 180, 180, 0.37);
  margin: 0 40px;
}
.header-fixed .right {
  position: relative;
  display: flex;
  width: 250px;
  padding-right: 60px;
  align-items: center;
}
.header-fixed .right .img-wrap {
  width: 36px;
  height: 36px;
  /* background-color: skyblue; */
  background: url("../assets/images/clock.png") no-repeat center/contain;
}

.header-fixed .right .container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 260px;
  height: 38px;
  border-radius: 16px;
  border: 2px solid #fce159;
  overflow: hidden;
}
.header-fixed .right .container .progress {
  box-sizing: border-box;
  display: flex;
  padding-right: 8px;
  height: 100%;
  width: 50%;
  justify-content: flex-end;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  align-items: center;
  background: linear-gradient(90deg, #fffd5c, #ff5c45);
}
.container .progress .conutDown {
  color: #fff;
}

.header-fixed .right .img-wrap img {
  width: 100%;
  height: 100%;
}
.bird {
  width: 80px;
  height: 80px;
  position: fixed;
  top: 20px;
}

.btn1 {
  background: url("../assets/images/button.png") no-repeat center/100%;
}
.btn1:focus,
.btn1:hover {
  background: url("../assets/images/button1.png") no-repeat center/100%;
}

.btn3 {
  background: url("../assets/images/button.png") no-repeat center/100%;
}
.btn3:focus,
.btn3:hover {
  background: url("../assets/images/button1.png") no-repeat center/100%;
}

.btn5 {
  background: url("../assets/images/button.png") no-repeat center/100%;
}
.btn5:focus,
.btn5:hover {
  background: url("../assets/images/button1.png") no-repeat center/100%;
}

.borderImage {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.landmark-wrap {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  position: absolute;
  top: 52%;
  left: 50%;
  width: 935px;
  height: 551px;
  padding: 75px 94px 165px 120px;
  background: url("../assets/images/dialog.png") no-repeat center/cover;
  transform: translate(-50%, -48%);
  z-index: 9;
}
.landmark-wrap .title {
  position: absolute;
  top: 3%;
  left: 51%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.5em;
  text-shadow: 1px 1px 5px #666;
}
.landmark-wrap .figure {
  width: 40%;
  height: 80%;
}
.landmark-wrap .figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.landmark-wrap .mark {
  flex: 1;
  padding-left: 20px;
}
.landmark-wrap .mark mark {
  line-height: 2em;
  font-size: 18px;
  background-color: transparent;
  border-bottom: 2px dashed #000;
}
.mask img.title {
  position: absolute;
  top: 20px;
  left: 30%;
  width: 343px;
  height: 100px;
}
.transition-scale {
  transition: all 0.5s;
}
.transition-scale:focus {
  transform: scale(1.1);
}
.arrows {
  position: absolute;
  right: 35px;
  top: 45%;
  height: 140px;
  margin-top: -50px;
}
.arrows .prev {
  width: 51px;
  height: 51px;
  display: block;
  background-image: url("../assets/images/arrow.png");
}
.arrows .next {
  width: 51px;
  height: 51px;
  display: block;
  transform: rotate(180deg);
  margin-top: 36px;
  background-image: url("../assets/images/arrow.png");
}
.prev:focus {
  background-image: url("../assets/images/arrow1.png");
  transform: scale(1.1);
}
.next:focus {
  background-image: url("../assets/images/arrow1.png");
  transform: scale(1.1) rotate(180deg);
}
.bgWrap *,
.bgWrap :focus {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(0, 0, 0, 0) !important;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #999;
  z-index: 999;
}
.loading .img {
  width: 100%;
  height: 100%;
}
.item.th .ranking,
.item.th .avatar,
.item.th .name,
.item.th .score {
  font-size: 26px !important;
}
.copyright {
  margin-top: 420px;
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: "custom";
  color: #ff5c67;
  text-align: center;
  z-index: 111;
}

.logo {
  position: absolute;
  right: -10px;
  top: -30px;
  width: 230px;
}
</style>
