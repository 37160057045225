// 检测屏幕宽高
function checkScreen() {
  // console.log("checkScreen()");
  // 缩放比
  var scalePercent;
  // 屏幕宽高
  var box_width = document.documentElement.clientWidth;
  var box_height = document.documentElement.clientHeight;
  let designWidth = 1334;
  let designHeight = 750;
  var top = 0,
    left = 0;
  var dom;
  // 设计图宽高比
  var designRatio = designWidth / designHeight;
  // 屏幕宽高比
  var documentRatio = box_width / box_height;
  // 判断屏幕与设计稿宽高比
  if (box_width >= designWidth && box_height >= designHeight) {
    if (designRatio > documentRatio) {
      scalePercent = box_width / designWidth;
    } else {
      scalePercent = box_height / designHeight;
    }
  } else if (box_width >= designWidth && box_height <= designHeight) {
    if (designRatio > documentRatio) {
      scalePercent = designWidth / box_width;
    } else {
      scalePercent = box_height / designHeight;
    }
  } else if (box_width <= designWidth && box_height <= designHeight) {
    if (designRatio > documentRatio) {
      scalePercent = box_width / designWidth;
    } else {
      scalePercent = box_height / designHeight;
    }
  } else if (box_width <= designWidth && box_height >= designHeight) {
    if (designRatio > documentRatio) {
      scalePercent = box_width / designWidth;
    } else {
      scalePercent = designHeight / box_height;
    }
  }
  left = box_width / 2 - designWidth / 2;
  top = box_height / 2 - designHeight / 2;

  dom = document.getElementsByClassName("gameWapper")[0];
  if (dom) {
    dom.style["top"] = top + "px";
    dom.style["left"] = left + "px";
    dom.style["transform"] = "scale(" + scalePercent + ")";
  }

  // dom = document.getElementsByClassName("landmark-wrap")[0];
  // if (dom) {
  //   dom.style["top"] = top + "px";
  //   dom.style["left"] = left + "px";
  //   dom.style["transform"] = "scale(" + scalePercent + ")";
  // }

  // // 游戏结束弹窗跟随高度动态调整
  // dom = document.getElementsByClassName('overDia')[0];
  // if (dom) {
  // 	dom.style['zoom'] = box_height / (1202 * 0.5) * 0.8;
  // }
}
// 检测屏幕宽高自适应
window.onresize = checkScreen;
checkScreen();
export default checkScreen;
