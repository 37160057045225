<template>
  <div id="app">
    <game1 class="gameWapper"></game1>
    <!-- <game2 class="gameWapper"></game2> -->
  </div>
</template>

<script>
import checkScreen from "./plugins/checkscreen.js";

import game1 from "./views/game1.vue";
// import game2 from "./views/game2.vue";
export default {
  name: "App",
  components: {
    game1,
    // game2,
  },
  data() {
    return {
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      underBackgroundImg: require("./assets/images/bg1.png"),
      value: 50,
    };
  },
  mounted() {
    checkScreen();
  },
};
</script>
<style>
body {
  margin: 0;
  overflow: hidden;
}
* {
  user-select: none;
}
#app {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
body {
  background-color: #000;
}
.gameWapper {
  position: relative;
}
.underBackground {
  position: fixed;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  filter: blur(5px);
  opacity: 0.8;
}
.underBackground {
  background-image: url("./assets/images/bg1.png");
}
.scorll-wrap::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scorll-wrap::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #adadad57;
}
.scorll-wrap::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  /* background: #ededed; */
}
</style>
