export default [
  require("../assets/images/gameName.png"),

  require("../assets/images/bg1.png"),
  require("../assets/images/cover_map.jpeg"),
  require("../assets/images/07_score30.png"),
  require("../assets/images/01_score10.png"),
  require("../assets/images/role/1.png"),
  require("../assets/images/role/qiqi.png"),
  require("../assets/images/role/2.png"),
  require("../assets/images/role/nuna.png"),
  require("../assets/images/role/4.png"),
  require("../assets/images/role/popo.png"),
  require("../assets/images/role/6.png"),
  require("../assets/images/role/nini.png"),

  require("../assets/images/prop1.png"),
  require("../assets/images/prop2.png"),

  require("../assets/images/landmark/长城.png"),
  require("../assets/images/landmark/故宫.png"),
  require("../assets/images/landmark/华表.png"),
  require("../assets/images/landmark/圆明园.png"),
  require("../assets/images/landmark/天安门.png"),
  require("../assets/images/landmark/水立方.png"),
  require("../assets/images/landmark/人民大会堂.png"),
  require("../assets/images/landmark/鸟巢.png"),
  require("../assets/images/landmark/天坛.png"),

  require("../assets/images/landmark/岱庙.png"),
  require("../assets/images/landmark/红门.png"),
  require("../assets/images/landmark/中天门.png"),
  require("../assets/images/landmark/十八盘.png"),
  require("../assets/images/landmark/南天门.png"),
  require("../assets/images/landmark/天街.png"),
  require("../assets/images/landmark/五岳独尊.png"),
  require("../assets/images/landmark/日观峰.png"),
  require("../assets/images/landmark/玉皇顶.png"),

  require("../assets/images/landmark/大雁塔.png"),
  require("../assets/images/landmark/大唐芙蓉园.png"),
  require("../assets/images/landmark/鼓楼.png"),
  require("../assets/images/landmark/壶口瀑布.png"),
  require("../assets/images/landmark/钟楼.png"),
  require("../assets/images/landmark/华清宫.png"),
  require("../assets/images/landmark/大唐不夜城.png"),
  require("../assets/images/landmark/灞桥.png"),
  require("../assets/images/landmark/兵马俑3.png"),

  require("../assets/images/cover_bj.jpeg"),

  require("../assets/images/cover_ts.jpeg"),

  require("../assets/images/cover_xa.jpeg"),

  require("../assets/images/button.png"),
  require("../assets/images/button1.png"),

  require("../assets/images/dialog.png"),

  require("../assets/images/bg3.png"),

  require("../assets/images/role/奇奇.png"),
  require("../assets/images/role/努娜.png"),
  require("../assets/images/role/西西.png"),
  require("../assets/images/role/珀珀.png"),
  require("../assets/images/role/沐沐.png"),
  require("../assets/images/role/妮妮.png"),
  require("../assets/images/role/A博士.png"),
  require("../assets/images/role/小优.png"),

  require("../assets/images/title01.png"),
  require("../assets/images/title02.png"),

  // require("../assets/images/map/北京地图1.jpg"),
  // require("../assets/images/map/北京地图2.jpg"),
  // require("../assets/images/map/北京地图3.jpg"),

  // require("../assets/images/map/泰山地图1.jpg"),
  // require("../assets/images/map/泰山地图2.jpg"),
  // require("../assets/images/map/泰山地图3.jpg"),

  // require("../assets/images/map/西安地图1.jpg"),
  // require("../assets/images/map/西安地图2.jpg"),
  // require("../assets/images/map/西安地图3.jpg"),
];
