<template>
  <div class="container">
    <div class="img-wrap">
      <img :src="avatar" />
    </div>
    <div class="score">
      <div class="tag" v-if="score < maxScore" :style="{ width: (score / maxScore) * 100 + '%' }"></div>
      <div class="tag" v-else :style="{ width: 100 + '%' }"></div>
      <div class="text" v-if="score < maxScore">{{ score }}/{{ maxScore }}</div>
      <div class="text" v-else>{{ maxScore }}/{{ maxScore }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["score", "maxScore", "avatar"],
  watch: {
    score(oldValue, value) {
      // console.log(oldValue, value);
      if (oldValue < this.maxScore && value >= this.maxScore) {
        console.log("success");
        this.$emit("confirm");
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 6vh;
  width: 300px;
  height: 80px;
}
.container .img-wrap {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f57f63;
  border: 0.4vh solid #fff;
}
.container .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.container .score {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-radius: 24px;
  /* border: 2px solid #fdd47e; */
  background: linear-gradient(180deg, #def292, #75d695);
  box-shadow: 2px 2px 2px #e6e29f inset, -2px 2px 2px #e6e29f inset;
}
.container .score .tag {
  height: 100%;
  border-radius: 24px;
  background: linear-gradient(180deg, #aee067, #54b381);
  /* aee167 */
  /* 53b281 */
  transition: width 1s;
}
.container .score .text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 2px #999;
}
</style>
