import TWEEN from "@tweenjs/tween.js";

export default {
  install: function(Vue) {
    Vue.prototype.$tween = this;
    Vue.prototype.$tweener = TWEEN;
  },
  pause: function(tw) {
    console.log(tw);
    tw.pause();
  },
  stop: function(tw) {
    console.log("stop");
    tw.stop();
  },
  /**
   *
   * @param {Object} target
   * @param {object} to
   * @param {Number} during 动画时间
   * @param {Number} delay 延迟时间
   * @param {Function} easing 动画函数
   * @param {Function} onUpdate
   * @param {Function} onComplete
   * @returns
   */
  fade: function(target, to, during, delay, easing, onUpdate, onComplete) {
    if (!target || !to) {
      return;
    }
    if (during == null) {
      during = 260;
    }
    let tw = new TWEEN.Tween(target).to(to, during);
    if (delay) tw.delay(delay);
    tw.easing(easing || TWEEN.Easing.Linear.None);
    if (onUpdate) tw.onUpdate(onUpdate);
    if (onComplete) tw.onComplete(onComplete);
    tw.start();
    return tw;
  },
};
